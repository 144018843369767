import React from 'react';
import { Snackbar } from '@mui/material';
import MuiAlert from '@mui/material/Alert';

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const AlertSnackbar = ({ open, handleClose, message, severity }) => {
  let modifiedMessage;  
  if(typeof message === "string"){
    modifiedMessage = <span dangerouslySetInnerHTML={{ __html: message }} />;
  } else {
    modifiedMessage = message;
  }

  return (
    <Snackbar
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={open}
      autoHideDuration={5000}
      onClose={handleClose}
    >
      <Alert onClose={handleClose} severity={severity} sx={{ width: '100%', "& a": { textDecoration: "underline" } }}>
          { modifiedMessage }
      </Alert>
    </Snackbar>
  );
};

export default AlertSnackbar;