import React, { useEffect, useState } from "react";
import {
    Paper,
    Box,
    Typography,
    Divider,
    Grid,
    FormControl,
    MenuItem,
    Select
} from "@mui/material";
import usersService from "../../services/usersService";
import MUITableComponent from "../../components/MUITableComponent";

const SurveyReportPage = () => {
    const [totalResponse, setTotalResponse] = useState(0);
    const [positiveResponse, setPositiveResponse] = useState(0);
    const [negativeResponse, setNegativeResponse] = useState(0);
    const [averageResponse, setAverageResponse] = useState(0);
    const [fdTableData, setFdTableData] = useState([])
    const [selectedOption, setSelectedOption] = useState("freshdesh-ticket-closed")
    const columns = [
      { id: "name", label: "Name", sortable: true},
      { id: "email", label: "Email ID", sortable: true},
      { id: "emailStatus", label: "Email Status", sortable: true,},
      { id: "status", label: "Survey Status", sortable: true,},
      { id: "response", label: "Response", sortable: true},
      { id: "updatedAt", label: "Last Updated", sortable: true}
    ]

    const labelOptions = [
      { value: 'freshdesh-ticket-closed', label: 'FD Ticket Resolve' },
  ];

  const emojiMapping = (type) => {
    switch (type) {
      case "good": return '😍'
      case "average": return '😐'
      case "bad": return '🙁'
      default: return '-'
    }
  }
    const setPageDisplay = (data) => {
      let totalResponses = 0;
      let positiveResponses = 0;
      let negativeResponses = 0;
      let averageResponses = 0;
    
      // Map through the data to format it for the table and calculate counts
      const formattedData = data.map(item => {
        // Count only responses with a status of "completed"
        if (item.status === "completed") {
          totalResponses += 1;
          if (item.response === "good") positiveResponses += 1;
          if (item.response === "bad") negativeResponses += 1;
          if (item.response === "average") averageResponses += 1;
        }
    
        return {
          name: item.userName || `-`,
          email: item.emailLog.recipient.slice(1, -1),
          status: item.status,
          emailStatus: item.emailLog.status,
          response: (<span style={{ fontSize: '1.5rem' }}>{emojiMapping(item.response)}</span>),
          updatedAt: new Date(item.updatedAt).toLocaleString('en-US', {
            year: 'numeric',
            month: 'short',
            day: '2-digit',
            hour: '2-digit',
            minute: '2-digit',
            hour12: true,
          }),
        };
      });
    
      // Update the table data state
      setFdTableData(formattedData);
    
      // Update the response counts
      setTotalResponse(totalResponses);
      setPositiveResponse(positiveResponses);
      setNegativeResponse(negativeResponses);
      setAverageResponse(averageResponses);
    }
    const fetchUserFeedback = async(taskName) => {
      try {
        const resp = await usersService.fetchSurveyReportData({taskName: taskName})
        setPageDisplay(resp.data.data)
        console.log(resp);
      } catch (error) {
        console.log(error);
      }
    }

    useEffect(() => {
      fetchUserFeedback(selectedOption)
    }, [selectedOption]);
  return (
    <Box>
        <Typography variant="h1" gutterBottom>
            Survey Report For
            <Select
                labelId="label-select"
                value={selectedOption}
                className="ml-8"
                onChange={(e)=>{setSelectedOption(e.target.value)}}
                label="Label"
            >
                {labelOptions.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                        {option.label}
                    </MenuItem>
                ))}
            </Select>
        </Typography>
        <Divider sx={{ marginBottom: "1em" }} />
        <Box mb={3}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={3}>
            <Paper
              elevation={2}
              sx={{
                p: 3,
                textAlign: "center",
                borderRadius: "1rem",
                color: "#2196F3",
              }}
            >
              <Typography
                variant="h6"
                sx={{ fontSize: "4rem", fontWeight: "700", lineHeight: "1.1" }}
              >
                {totalResponse}
              </Typography>
              <Typography
                variant="subtitle1"
                sx={{ fontSize: "1.5rem", fontWeight: "600" }}
              >
                Total Response
              </Typography>
            </Paper>
          </Grid>
          <Grid item xs={12} sm={3}>
            <Paper
              elevation={2}
              sx={{
                p: 3,
                textAlign: "center",
                borderRadius: "1rem",
                color: "#4BA155",
              }}
            >
              <Typography
                variant="h6"
                sx={{ fontSize: "4rem", fontWeight: "700", lineHeight: "1.1" }}
              >
                {positiveResponse}
              </Typography>
              <Typography
                variant="subtitle1"
                sx={{ fontSize: "1.5rem", fontWeight: "600" }}
              >
                Excellent
              </Typography>
            </Paper>
          </Grid>
          <Grid item xs={12} sm={3}>
            <Paper
              elevation={2}
              sx={{
                p: 3,
                textAlign: "center",
                borderRadius: "1rem",
                color: "#f4a930",
              }}
            >
              <Typography
                variant="h6"
                sx={{ fontSize: "4rem", fontWeight: "700", lineHeight: "1.1" }}
              >
                {averageResponse}
              </Typography>
              <Typography
                variant="subtitle1"
                sx={{ fontSize: "1.5rem", fontWeight: "600" }}
              >
                Average
              </Typography>
            </Paper>
          </Grid>
          <Grid item xs={12} sm={3}>
            <Paper
              elevation={2}
              sx={{
                p: 3,
                textAlign: "center",
                borderRadius: "1rem",
                color: "#d32f2f",
              }}
            >
              <Typography
                variant="h6"
                sx={{ fontSize: "4rem", fontWeight: "700", lineHeight: "1.1" }}
              >
                {negativeResponse}
              </Typography>
              <Typography
                variant="subtitle1"
                sx={{ fontSize: "1.5rem", fontWeight: "600" }}
              >
                Not Good
              </Typography>
            </Paper>
          </Grid>
        </Grid>
        </Box>
        <MUITableComponent
          tableData={fdTableData}
          columns={columns}
          hasActions={true}
        />
    </Box>
  )
}

export default SurveyReportPage