// src/App.js
import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import AdminRoutes from "./routes";
import "./index.css";
import theme from "./styles/theme";
import axios from "axios";
import { UserProvider } from "./context/UserContext";
axios.defaults.baseURL = process.env.REACT_APP_API_BASE_URL;
axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*";
axios.defaults.headers.common["Content-Type"] = "application/json";

function App() {
  return (
    <UserProvider>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Router>
          <AdminRoutes />
        </Router>
      </ThemeProvider>
    </UserProvider>
  );
}

export default App;
