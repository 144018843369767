import React, { useEffect, useState } from "react";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Chip,
  Box,
  IconButton,
  Divider,
  CircularProgress,
} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useNavigate } from "react-router-dom";
import MUITableComponent from "../../components/MUITableComponent";
import usersService from "../../services/usersService";
const UsersStatusPage = () => {
  const [usersStatusGroup, setUsersStatusGroup] = useState([]);
  const [expandedAccordion, setExpandedAccordion] = useState(false);
  const [dataForBatch, setDataForBatch] = useState({}); 
  const [loadingBatch, setLoadingBatch] = useState(null); 
  
  const navigate = useNavigate();
  const getStatusColors = (status) => {
    switch (status) {
      case "sent":
        return { bg: "#e6f7ed", text: "#1e4e31" };
      case "pending":
        return { bg: "#fff4e5", text: "#663c00" };
      case "rejected":
        return { bg: "#fce8e8", text: "#621b18" };
      default:
        return { bg: "#f0f0f0", text: "#333333" };
    }
  };

  const columns = [
    { id: "firstName", label: "First Name", sortable: true, width: "20%" },
    { id: "email", label: "Email", sortable: true, width: "25%" },
    { id: "updatedAt", label: "Updated At", sortable: true, width: "25%" },
    {
      id: "status",
      label: "Status",
      sortable: true,
      width: "10%",
      sortFunction: (a, b) =>
        a.status.props.label.localeCompare(b.status.props.label),
    },
  ];

  useEffect(() => {
    fetchAllUserStatus();
  }, []);
  
  const fetchAllUserStatus = async () => {
    const userStatus = await usersService.getBatchData();
    setUsersStatusGroup(userStatus?.data?.data);
  };

  const generateTableData = (batch) => {
    if(!batch.data){
      return;
    }
    return batch.data?.map((user) => {
      const statusColors = getStatusColors(user.status.toLowerCase());
      return {
        firstName: user["user.firstName"] || "N/A",
        email: user["user.email"] || "N/A",
        updatedAt: new Date(user.updatedAt).toLocaleDateString("en-US", {
          year: "numeric",
          month: "long",
          day: "numeric",
        }) || "N/A",
        status: (
          <Chip
            label={user.status.charAt(0).toUpperCase() + user.status.slice(1)}
            style={{
              backgroundColor: statusColors.bg,
              color: statusColors.text,
              padding: "1px 5px",
              fontWeight: "500",
            }}
            size="small"
          />
        ),
      };
    });
  };

  const handleAccordionChange = (batch) => (event, isExpanded) => {
    setExpandedAccordion(isExpanded ? batch.batchId : false);
    if (isExpanded && !dataForBatch[batch.batchId]) {
      let params = {
        batchId: batch.batchId,
        subjectTemplate: batch.subjectTemplate,
      };
      fetchDataForBatch(params);
    }
  };

  const fetchDataForBatch = async (params) => {
    setLoadingBatch(params.batchId);
    try {
      const userDataFromBatch = await usersService.getUsersStatus(params);
      setDataForBatch((prevData) => ({
        ...prevData,
        [params.batchId]: userDataFromBatch?.data, // Save data for the corresponding batchId
      }));
    } catch (error) {
      console.error("Error fetching data for batchId:", params.batchId, error);
    } finally {
      setLoadingBatch(null); // Stop loading state after fetch
    }
  };
  return (
    <div>
      <Box display="flex" alignItems="center" mb={2}>
        <IconButton
          onClick={() => navigate("/users")}
          aria-label="go back"
          title="Go Back"
          sx={{ marginBottom: "0.35em", marginRight: "0.1em" }}
        >
          <ArrowBackIcon />
        </IconButton>
        <Typography variant="h1" gutterBottom>
          Email Status
        </Typography>
      </Box>

      <Divider sx={{ marginBottom: "1em" }} />
      {usersStatusGroup.length > 0 &&
        usersStatusGroup?.map((batch, index) => (
          <Accordion key={batch.batchId} sx={{ marginBottom: "1rem" }}
          expanded={expandedAccordion === batch.batchId}
          onChange={handleAccordionChange(batch)}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls={`panel${index}-content`}
              id={`panel${index}-header`}
            >
              <Typography>{batch.subjectTemplate}</Typography>
              <Typography style={{ marginLeft: "auto" }}>
                {new Date(batch.date).toLocaleString()}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
               {loadingBatch === batch.batchId ? (
               <Box
               display="flex"
               justifyContent="center"
               alignItems="center"
               height="150px" 
             >
               <CircularProgress />
             </Box>
            ) : dataForBatch[batch.batchId] ? (
              <MUITableComponent
                tableData={generateTableData(dataForBatch[batch.batchId])}  // Pass fetched data
                columns={columns}
              />
            ) : (
              <Typography>No data available.</Typography>
            )}
            </AccordionDetails>
          </Accordion>
        ))}
    </div>
  );
};
export default UsersStatusPage;
