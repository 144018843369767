import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Table, TableBody, TableCell, TableHead, TableRow, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";

const ClientListDialog = ({ openDialog, handleCloseDialog, selectedRow, userData }) => {
  const navigate= useNavigate();
  const handleClickUserSpcificFeaturePage=(selectedUser)=>{
    navigate(`/user_reports/${selectedUser.userId}`,{state:{email:selectedUser.email,name:selectedUser.username,userId:selectedUser.userId}});
    handleCloseDialog();
  }
  return (<>
    <Dialog open={openDialog} onClose={handleCloseDialog}>
      <DialogTitle>{selectedRow?.featureName} Client Used Count Details
        <Typography></Typography></DialogTitle>
      <DialogContent>
        <Box>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell>Username</TableCell>
                <TableCell>Email</TableCell>
                <TableCell>Usage Count</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {userData.map((user, index) => (
                <TableRow key={index} onClick={()=>{handleClickUserSpcificFeaturePage(user)}}>
                  <TableCell>{user.username}</TableCell>
                  <TableCell>{user.email}</TableCell>
                  <TableCell>{user.usageCount}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCloseDialog} color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog></>)

}
export default ClientListDialog;