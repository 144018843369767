export const getCurrentTimestamp = () => {
  const now = new Date();
  return now.toISOString().replace(/[-:]/g, "").split(".")[0];
};

export const formatDateToLongString = (dateString) => {
  const date = new Date(dateString);
  return date.toLocaleDateString("en-US", {
    year: "numeric",
    month: "long",
    day: "numeric",
  });
};

export const formatDateTimeToLongString = (utcDateString, timezone = null) => {
  // Parse the UTC date string
  const [datePart, timePart] = utcDateString.split(" ");
  const [year, month, day] = datePart.split("-");
  const [hours, minutes, seconds] = timePart.split(":");

  // Create Date object in UTC
  const date = new Date(
    Date.UTC(
      parseInt(year),
      parseInt(month) - 1, // Months are 0-indexed in JavaScript
      parseInt(day),
      parseInt(hours),
      parseInt(minutes),
      parseInt(seconds)
    )
  );

  // If no timezone is provided, use the local timezone
  if (!timezone) {
    timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  }

  // Create a formatter for the desired timezone
  const formatter = new Intl.DateTimeFormat("en-US", {
    year: "numeric",
    month: "long",
    day: "numeric",
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
    hour12: true,
    timeZone: timezone,
  });
  console.log("time", timezone, utcDateString, date);

  // Replace the comma with " at "
  return formatter.format(date);
};

// Helper function to convert month name to number (0-11)
function monthToNumber(month) {
  const months = {
    January: 0,
    February: 1,
    March: 2,
    April: 3,
    May: 4,
    June: 5,
    July: 6,
    August: 7,
    September: 8,
    October: 9,
    November: 10,
    December: 11,
  };
  return months[month];
}

export const truncateDescription = (text, maxLength = 45) => {
  if (text.length <= maxLength) return text;
  return text.substr(0, maxLength) + "...";
};

export const stripHtmlAndTruncate = (html, maxLength) => {
  const tmp = document.createElement("DIV");
  tmp.innerHTML = html;
  tmp.innerHTML = tmp.innerHTML.replace(/<\/(p|div|br|strong)>/gi, " ");
  let text = tmp.textContent || tmp.innerText || "";
  text = text.replace(/\s+/g, " ").trim();
  return text.length > maxLength ? text.slice(0, maxLength) + "..." : text;
};

export const generateUniqueIdentifier = () => {
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, (c) => {
    const r = (Math.random() * 16) | 0;
    const v = c === "x" ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
};

export const maxSizeInBytes = 1 * 1024 * 1024;

export const allowedTypes = ["image/jpeg", "image/png"];

export const allowedExtensions = ["jpg", "jpeg", "png"];

export const USER_STATUS_OPTIONS = [
  {
    label: "Active",
    value: "active",
  },
  {
    label: "Inactive",
    value: "inactive",
  },
];

export const extractFacebookUsername = (url) => {
  let username = "";
  let regex = /^https:\/\/www\.facebook\.com\/([a-zA-Z0-9._]+)\/?$/;
  let match = url.match(regex);
  if (match) {
    username = match[1];
  } else {
    regex = /^https:\/\/www\.facebook\.com\/profile\.php\?id=\d+\/?$/;
    match = url.match(regex);
    if (match) {
      username = url.split("id=")[1].split("/")[0];
    }
  }
  return username;
};

export const paymentStatuses = [
  { value: "pending", label: "Pending" },
  { value: "completed", label: "Completed" },
  { value: "failed", label: "Failed" },
  { value: "refunded", label: "Refunded" },
];

export const capitalizeFirstLetter = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

export const searchParams = {
  // "cancel-friend-requests": [
  //   {
  //     name: "skipClose",
  //     type: "boolean",
  //     description: "Skip closing connections or actions."
  //   },
  // ],
  "gain-reciprocity": [
    // {
    //   name: "useMbasicEnable",
    //   type: "boolean",
    //   description: "Enable the use of the basic mobile site.",
    // },
    {
      name: "useFriendFilter",
      type: "boolean",
      description: "Enable filtering friends based on certain criteria.",
    },
    // {
    //   "name": "skipClose",
    //   "type": "boolean",
    //   "description": "Skip closing connections or actions."
    // },
    // {
    //   "name": "cursorTimeGapMin",
    //   "type": "number",
    //   "description": "Minimum time gap between cursor actions.",
    //   "min": 0,
    //   "max": 1000
    // },
    // {
    //   "name": "cursorTimeGapMax",
    //   "type": "number",
    //   "description": "Maximum time gap between cursor actions.",
    //   "min": 0,
    //   "max": 1000
    // },
    // {
    //   "name": "apiTimeGapMin",
    //   "type": "number",
    //   "description": "Minimum time gap between API calls.",
    //   "min": 0,
    //   "max": 1000
    // },
    // {
    //   "name": "apiTimeGapMax",
    //   "type": "number",
    //   "description": "Maximum time gap between API calls.",
    //   "min": 0,
    //   "max": 1000
    // }
  ],
  // "scan-friend-activity": [
  //   {
  //     name: "dateFriended",
  //     type: "boolean",
  //     description: "Include the date when the friend was added.",
  //   },
  // {
  //   "name": "skipClose",
  //   "type": "boolean",
  //   "description": "Skip closing connections or actions."
  // },
  // {
  //   name: "maxPostToFetch",
  //   type: "number",
  //   description: "Maximum number of posts to fetch.",
  //   min: 1,
  //   max: 100,
  // },
  // {
  //   name: "maxTimeStampToFetch",
  //   type: "number",
  //   description: "Maximum timestamp for posts to fetch.",
  //   min: 0,
  //   max: 9999999999,
  // },
  // {
  //   "name": "cursorTimeGapMin",
  //   "type": "number",
  //   "description": "Minimum time gap between cursor actions.",
  //   "min": 0,
  //   "max": 1000
  // },
  // {
  //   "name": "cursorTimeGapMax",
  //   "type": "number",
  //   "description": "Maximum time gap between cursor actions.",
  //   "min": 0,
  //   "max": 1000
  // },
  // {
  //   "name": "apiTimeGapMin",
  //   "type": "number",
  //   "description": "Minimum time gap between API calls.",
  //   "min": 0,
  //   "max": 1000
  // },
  // {
  //   "name": "apiTimeGapMax",
  //   "type": "number",
  //   "description": "Maximum time gap between API calls.",
  //   "min": 0,
  //   "max": 1000
  // },
  //   {
  //     name: "postTimeGapMin",
  //     type: "number",
  //     description: "Minimum time gap between fetching posts.",
  //     min: 0,
  //     max: 1000,
  //   },
  //   {
  //     name: "postTimeGapMax",
  //     type: "number",
  //     description: "Maximum time gap between fetching posts.",
  //     min: 0,
  //     max: 1000,
  //   },
  //   {
  //     name: "gapAfterLikes",
  //     type: "number",
  //     description: "Gap after liking a post.",
  //     min: 0,
  //     max: 1000,
  //   },
  //   {
  //     name: "timeGapAfterLikes",
  //     type: "number",
  //     description: "Time gap after liking a post.",
  //     min: 0,
  //     max: 1000,
  //   },
  // ],
  // "prospect-by-post": [
  //   {
  //     name: "skipClose",
  //     type: "boolean",
  //     description": "Skip closing connections or actions."
  //   },
  //   {
  //     name: "cursorTimeGapMin",
  //     type: "number",
  //     description": "Minimum time gap between cursor actions.",
  //     "min": 0,
  //     "max": 1000
  //   },
  //   {
  //     name: "cursorTimeGapMax",
  //     type: "number",
  //     description": "Maximum time gap between cursor actions.",
  //     "min": 0,
  //     "max": 1000
  //   },
  //   {
  //     name: "apiTimeGapMin",
  //     type: "number",
  //     description: "Minimum time gap between API calls.",
  //     "min": 0,
  //     "max": 1000
  //   },
  //   {
  //     name: "apiTimeGapMax",
  //     type: "number",
  //     description: "Maximum time gap between API calls.",
  //     "min": 0,
  //     "max": 1000
  //   },
  // ],
  "generate-leads": [
    {
      name: "skipMbasic",
      type: "boolean",
      description: "Skip processing on the mobile basic (mbasic) version of the site."
    },
  ],
  "cancel-group-invite": [
    {
      name: "cursorTimeGapMin",
      type: "number",
      description: "Minimum time gap between cursor actions.",
      min: 0,
      max: 1000
    },
    {
      name: "cursorTimeGapMax",
      type: "number",
      description: "Maximum time gap between cursor actions.",
      min: 0,
      max: 1000
    },
  ],
};