import axios from "axios";
import reportsServiceConfig from "./reportsServiceConfig"
class reportsService {
    getPanelReports = (params) => axios.get(`${reportsServiceConfig.getPanelReports}`,{params});
    getUserDetails = (params) => axios.get(`${reportsServiceConfig.getUserDetails}`, {params});
    getUserSpecificReport = (params) => axios.get(`${reportsServiceConfig.getUserSpecificReports}`,{params});
    getTaskReportChart = (params) => axios.get(`${reportsServiceConfig.getTaskReportChart}`,{params});
}

const instance = new reportsService();

export default instance;
