import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  ButtonGroup,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  Typography,
} from "@mui/material";
import MUITableComponent from "../../components/MUITableComponent";
import { useLocation, useNavigate } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import BarChartIcon from "@mui/icons-material/BarChart";
import reportsService from "../../services/reportsService/reportsService";
import {
  calculateStartDate,
  featureMappings,
  featureNameMapping,
  generateDateRange,
  reverseFeatureNameMapping,
  userSpecificFeatureMapping,
} from "../../utils/helper";
import UserReportChart from "../dashboard/components/UserReportChart";
import { formatDateToLongString } from "../../utils/constant";
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "../../styles/datepicker.css";
const UserReportsPage = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [timeRange, setTimeRange] = useState("Month");
  const [startDate, setStartDate] = useState(
    calculateStartDate(timeRange.toLowerCase())
  );
  const [endDate, setEndDate] = useState(new Date());
  const [data, setData] = useState(null);
  const [openChart, setOpenChart] = useState(false);
  const [chartData, setChartData] = useState([]);
  const [totalResultChart, setTotalResultChart] = useState([]);
  const selectedUser = location.state || {};
  const columns = [
    {
      id: "featureName",
      label: "Feature Name",
      sortable: true,
      clickable: false,
    },
    {
      id: "usageCount",
      label: "Usage Count",
      sortable: true,
      clickable: false,
    },
    {
      id: "lastUsedDate",
      label: "Last Used Date",
      sortable: true,
      clickable: false,
    },
    {
      id: "totalResult",
      label: "Result",
      sortable: false,
      clickable: false,
    },
    { id: "graphView", label: "Graph view", sortable: false, clickable: true },
  ];

  const handleTimeRangeChange = (newRange) => {
    if (newRange && newRange !== timeRange) {
      if (newRange !== "custom") {
        setStartDate(calculateStartDate(newRange.toLowerCase()));
        setEndDate(new Date());
      } 
      if(newRange==="custom" || newRange==="All Time") {
        setStartDate(null);
        setEndDate(null);
      }
      setTimeRange(newRange);
    }
  };
  const processData = (data, startDate, endDate) => {
    const fullDateRange = generateDateRange(startDate, endDate);
    const dataMap = new Map(data.map(({ date, value }) => [date, value]));
    return fullDateRange.map((date) => ({
      date,
      value: dataMap.get(date) || 0,
    }));
  };
  const handleClickGraphView = async (row) => {
    // try{
    let queryparams = {
      userId: selectedUser.userId,
      taskName: reverseFeatureNameMapping[row.featureName] || row.featureName,
      startDate: startDate,
      endDate: endDate,
    };
    const response = await reportsService.getTaskReportChart(queryparams);
    const resp = createDataForTotalUsage(response.data.data);
    const results = createDataForTotalResult(response.data.data, row);
    let totalResultTransformedData = [];
    results.forEach((result) => {
      totalResultTransformedData.push({
        chartData : processData(result.chartData, startDate, endDate),
        label : result.element
        }
      );
    });
    const transformedData = processData(resp.chartData, startDate, endDate);
    setOpenChart(true);
    setChartData(transformedData);
    setTotalResultChart(totalResultTransformedData);
    // }
  };
  const createDataForTotalUsage = (data) => {
    const chartData = data.map((item) => {
      return {
        date: item.period,
        value: item.totalUsageCount || 0,
      };
    });

    const totalCount = chartData.reduce((acc, item) => acc + item.value, 0);
    return {
      chartData,
      totalCount,
    };
  };
  const createDataForTotalResult = (data, row) => {
    let allChartsData = [];
    featureMappings[reverseFeatureNameMapping[row.featureName]].forEach(
      (element) => {
        const chartData = data.map((item) => {
          return {
            date: item.period,
            value: item[element] || 0,
            title: element,
          };
        });

        const totalCount = chartData.reduce((acc, item) => acc + item.value, 0);
        allChartsData.push({
          chartData,
          totalCount,
          element
        });
      }
    );
    return allChartsData;
  };
  useEffect(() => {
    if (startDate && endDate && timeRange === "custom") {
      fetchData();
    }
  }, [startDate, endDate, timeRange]);

  useEffect(() => {
    if (timeRange !== "custom") {
      fetchData();
    }
  }, [timeRange]);

  const fetchData = async () => {
    try {
      let queryParam = {};
      if (timeRange !== "All Time") {
        queryParam = {
          startDate: startDate,
          endDate: endDate,
        };
      }
      queryParam.userId = selectedUser.userId;
      const response = await reportsService.getUserSpecificReport(queryParam);
      const formattedData = formatDataForTable(response.data.data);
      setData(formattedData);
    } catch (err) {
      console.log(err);
    }
  };
  const formatDataForTable = (data) => {
    return data.map((item) => {
      const totalResultValue = userSpecificFeatureMapping[
        item.featureName
      ].reduce((acc, element) => {
        acc = element + ":" + item[element] + " " + acc;
        return acc;
      }, "");
      return {
        featureName: featureNameMapping[item.featureName] || item.featureName,
        usageCount: item.featureUsageCount,
        lastUsedDate: formatDateToLongString(item.lastUsageTime),
        totalResult: totalResultValue,
        graphView: <BarChartIcon />,
      };
    });
  };

  const handleClose = () => {
    setOpenChart(false);
  };
  const handleCustomTimeRangeChange = (dates) => {
    let [start, end] = dates;
    if (end) {
      end = new Date(end.setHours(23, 59, 59, 59));
    }
    setStartDate(start);
    setEndDate(end);
  };
  return (
    <div>
      <Box display="flex" alignItems="center" mb={2}>
        <IconButton
          onClick={() => navigate("/users")}
          aria-label="go back"
          title="Go Back"
          sx={{ marginBottom: "0.35em", marginRight: "0.1em" }}
        >
          <ArrowBackIcon />
        </IconButton>
        <Typography variant="h1" gutterBottom>
          User Reports
        </Typography>
      </Box>
      <Divider sx={{ marginBottom: "1em" }} />
      <Box display="flex" sx={{justifyContent:'space-between'}}>
      <Box display="flex" gap={2} justifyContent="between">
        <ButtonGroup
          sx={{ marginBottom: "1em" }}
          variant="outlined"
          color="primary"
        >
          <Button
            onClick={() => handleTimeRangeChange("Month")}
            variant={timeRange === "Month" ? "contained" : "outlined"}
          >
            Month
          </Button>
          <Button
            onClick={() => handleTimeRangeChange("Week")}
            variant={timeRange === "Week" ? "contained" : "outlined"}
          >
            Week
          </Button>
          <Button
            onClick={() => handleTimeRangeChange("Year")}
            variant={timeRange === "Year" ? "contained" : "outlined"}
          >
            Year
          </Button>
          <Button
            onClick={() => handleTimeRangeChange("All Time")}
            variant={timeRange === "All Time" ? "contained" : "outlined"}
          >
            All Time
          </Button>
          <Button
            onClick={() => handleTimeRangeChange("custom")}
            variant={timeRange === "custom" ? "contained" : "outlined"}
          >
            Custom
          </Button>
        </ButtonGroup>
        <Box sx={{ height: "27px" }}>
          {timeRange === "custom" && (
            <ReactDatePicker
              className=" w-full bg-white border border-gray-300 rounded-md shadow-sm p-2 text-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
              selected={startDate}
              onChange={(dates) => {
                handleCustomTimeRangeChange(dates);
              }}
              startDate={startDate}
              endDate={endDate}
              selectsRange
              isClearable
              placeholderText="Select Range"
              popperClassName="custom-datepicker-popup"
            />
          )}
        </Box>
      </Box>
      { (startDate && endDate) && <Typography>{formatDateToLongString(startDate)} - {formatDateToLongString(endDate)}</Typography>}
      </Box>
      <Typography variant="h2" gutterBottom>
        {selectedUser.name}
      </Typography>
      {data && (
        <MUITableComponent
          columns={columns}
          tableData={data}
          handleCellClick={handleClickGraphView}
        />
      )}

      <Dialog open={openChart} onClose={handleClose} fullWidth maxWidth="md">
        <DialogTitle>Graph View</DialogTitle>
        <DialogContent>
          <UserReportChart data={chartData} title={"Total Usage Count"} />
          {totalResultChart.map((result, index) => (
            <UserReportChart key={index} data={result.chartData} title={result.label} />
          ))}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default UserReportsPage;
