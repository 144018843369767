import React from 'react';
import Chart from 'react-apexcharts';

const UserReportChart = ({data, title}) => {
  const chartData={
    series: [
      {
        name: title,
        data: data.map(item=>item.value),
      },
    ],
    options: {
      chart: {
        type: 'bar',
        height: 350,
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: '55%',
        },
      },
      xaxis: {
        categories: data.map(item=>item.date),
      },
      yaxis: {
        title: {
          text: title,
        },
      },
      tooltip: {
        y: {
          formatter: (val) => `${val}`,
        },
      },
    },
  };

  return (
    <div>
      <Chart
        options={chartData.options}
        series={chartData.series}
        type="bar"
        height={350}
      />
    </div>
  );
};

export default UserReportChart;
