import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import {
  Box,
  Divider,
  Typography,
  Button,
  Paper,
  CircularProgress,
  Backdrop,
  RadioGroup,
  FormControlLabel,
  Radio,
  FormHelperText,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import SelectInputComponent from "../../components/SelectInputComponent";
import TextInputComponent from "../../components/TextInputComponent";
import AlertSnackbar from "../../components/AlertSnackbar";
import {
  USER_STATUS_OPTIONS,
  extractFacebookUsername,
  searchParams,
} from "../../utils/constant";
import usersService from "../../services/usersService";
import TextInputComponent2 from "../../components/TextInputComponent2";
import paramService from "../../services/paramService";

const createUpdateSchema = (isFbUrlRequired) =>
  yup.object().shape({
    firstName: yup.string().required("First name is required"),
    lastName: yup.string(),
    email: yup.string().email("Invalid email").required("Email is required"),
    status: yup.string().required("Status is required"),
    fbUrl: isFbUrlRequired
      ? yup
          .string()
          .url("Invalid URL")
          .required("Facebook Profile URL is required")
      : yup.string().url("Invalid URL"),
  });

const resetPasswordSchema = yup.object().shape({
  password: yup
    .string()
    .min(6, "Password must be at least 6 characters")
    .required("Password is required"),
  confirmPassword: yup
    .string()
    .oneOf([yup.ref("password"), null], "Passwords must match")
    .required("Confirm password is required"),
});

function UserDetailsPage() {
  const { userId } = useParams();
  const navigate = useNavigate();
  const [user, setUser] = useState(null);
  const [canResetPassword, setCanResetPassword] = useState(true);
  const [isFbUrlRequired, setIsFbUrlRequired] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedFeature, setSelectedFeature] = useState("");
  const [paramsData, setParamsData] = useState([]);
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: "",
    severity: "success",
  });

  const {
    control: updateControl,
    handleSubmit: handleUpdateSubmit,
    formState: { errors: updateErrors },
    reset: updateReset,
  } = useForm({
    resolver: yupResolver(createUpdateSchema(isFbUrlRequired)),
    defaultValues: {
      firstName: "",
      lastName: "",
      email: "",
      status: "active",
      fbUrl: "",
    },
  });

  const {
    control: resetPasswordControl,
    handleSubmit: handleResetPasswordSubmit,
    formState: { errors: resetPasswordErrors },
    reset: resetPasswordReset,
  } = useForm({
    resolver: yupResolver(resetPasswordSchema),
    defaultValues: {
      password: "",
      confirmPassword: "",
    },
  });

  const {
    control: searchParamsControl,
    handleSubmit: handleSetSearchParamsSubmit,
    formState: { errors: searchParamsErrors },
    reset: resetSearchParams,
  } = useForm({
    defaultValues: {
      featuredName: "",
      ...Object.fromEntries(
        Object.values(searchParams)
          .flat()
          .map((param) => [param.name, ""])
      ),
    },
  });

  const handleFeatureChange = (event) => {
    const selectedFeatureName = event.target.value;
    setSelectedFeature(selectedFeatureName);

    // Find the matching feature from the stored paramsData
    const matchingFeature = paramsData.find(
      (item) => item.featureName === selectedFeatureName
    );

    if (matchingFeature && matchingFeature.params) {
      // Set the form values based on the matching feature's params
      const formValues = {
        featuredName: selectedFeatureName,
        ...Object.fromEntries(
          Object.entries(matchingFeature.params).map(([key, value]) => [
            key,
            value === null || value === undefined ? "" : String(value),
          ])
        ),
      };
      resetSearchParams(formValues);
    } else {
      // If no matching feature found, reset the form but keep the selected feature name
      const defaultValues = {
        featuredName: selectedFeatureName,
        ...(searchParams[selectedFeatureName]
          ? Object.fromEntries(
              searchParams[selectedFeatureName].map((param) => [param.name, ""])
            )
          : {}),
      };
      resetSearchParams(defaultValues);
    }
  };

  const onSubmitSearchParams = async (data) => {
    console.log("Search Params:", data);
    setIsLoading(true);
    try {
      // Get the parameters for the selected feature
      const featureParams = searchParams[selectedFeature] || [];
      const paramNames = featureParams.map((param) => param.name);

      const filteredData = Object.entries(data).reduce((acc, [key, value]) => {
        if (paramNames.includes(key) && value !== "" && value !== undefined) {
          // Convert "true" and "false" strings to boolean values
          if (value === "true") acc[key] = true;
          else if (value === "false") acc[key] = false;
          else if (!isNaN(value))
            acc[key] = Number(value); // Convert numeric strings to numbers
          else acc[key] = value;
        }
        return acc;
      }, {});

      const updateData = {
        featureName: selectedFeature,
        params: filteredData,
      };

      console.log("Data to be sent:", updateData);

      await paramService.addParams(updateData, userId);

      // Update the paramsData state
      setParamsData((prevData) => {
        const newData = prevData.filter(
          (item) => item.featureName !== selectedFeature
        );
        return [...newData, updateData];
      });

      setSnackbar({
        open: true,
        message: "User params details updated successfully",
        severity: "success",
      });
    } catch (error) {
      console.error("Error updating user params details:", error);
      setSnackbar({
        open: true,
        message: error?.response?.data?.message,
        severity: "error",
      });
    }
    setIsLoading(false);
  };

  const fetchUser = async (parsedUserId) => {
    try {
      const respFetchUser = await usersService.getUserDataById(parsedUserId);
      const userData = respFetchUser?.data?.data;
      setUser(userData);
      const facebookUserName =
        userData?.users_FacebookUsers[0]?.facebookUser?.facebookUserName || "";
      const fbUrl = facebookUserName
        ? `https://www.facebook.com/${facebookUserName}`
        : "";

      updateReset({
        firstName: userData.firstName || "",
        lastName: userData.lastName || "",
        email: userData.email || "",
        status: userData.status || "active",
        fbUrl: fbUrl,
      });

      setIsFbUrlRequired(!!facebookUserName);
      setCanResetPassword(!!facebookUserName);
    } catch (error) {
      console.log(error);
      setSnackbar({
        open: true,
        message: "Error fetching user data",
        severity: "error",
      });
    }
  };

  const fetchParamsData = async () => {
    try {
      const respFetchParams = await paramService.getParamsByAdmin(userId);
      setParamsData(respFetchParams.data.data);
    } catch (error) {
      console.error("Error fetching params:", error);
      setSnackbar({
        open: true,
        message: "Error fetching user params",
        severity: "error",
      });
    }
  };

  useEffect(() => {
    const parsedUserId = parseInt(userId);
    fetchUser(parsedUserId);
    fetchParamsData();
  }, [userId]);

  const onSubmitUpdate = async (data) => {
    setIsLoading(true);
    try {
      const facebookUserName = data.fbUrl
        ? extractFacebookUsername(data.fbUrl)
        : "";
      const updateData = {
        ...data,
        facebookUserName,
      };
      await usersService.updateUserDetail(updateData, userId);
      setSnackbar({
        open: true,
        message: "User details updated successfully",
        severity: "success",
      });
    } catch (error) {
      console.error("Error updating user details:", error);
      setSnackbar({
        open: true,
        message: error?.response?.data?.message,
        severity: "error",
      });
    }
    setIsLoading(false);
  };

  const onSubmitReset = async (data) => {
    if (!canResetPassword) {
      setSnackbar({
        open: true,
        message: "Cannot reset password: Facebook username is missing",
        severity: "error",
      });
      return;
    }
    setIsLoading(true);
    try {
      await usersService.updateUserPassword(
        {
          password: data.password,
          confirmPassword: data.confirmPassword,
        },
        userId
      );
      setSnackbar({
        open: true,
        message: "Password reset successfully",
        severity: "success",
      });
      resetPasswordReset(); // Clear password fields after successful reset
    } catch (error) {
      console.error("Error resetting password:", error);
      setSnackbar({
        open: true,
        message: error?.response?.data?.message,
        severity: "error",
      });
    }
    setIsLoading(false);
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbar((prev) => ({ ...prev, open: false }));
  };

  return (
    <>
      <Button
        startIcon={<ArrowBackIcon />}
        onClick={() => navigate("/users")}
        sx={{ mb: 2 }}
      >
        Back to Users
      </Button>
      <Typography variant="h1" gutterBottom>
        User Details
      </Typography>

      <Divider sx={{ marginBottom: "1em" }} />

      <Box sx={{ margin: "auto", marginBottom: "1em" }}>
        <Paper variant="outlined" sx={{ borderRadius: "8px" }}>
          <Box sx={{ padding: "0.8rem 1.2rem" }}>
            <Typography variant="h2" component="h2" gutterBottom>
              Basic Information
            </Typography>
          </Box>
          <Divider />
          <form onSubmit={handleUpdateSubmit(onSubmitUpdate)}>
            <Box sx={{ padding: "0.8rem 1.2rem" }}>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-12">
                <Controller
                  name="firstName"
                  control={updateControl}
                  render={({ field }) => (
                    <TextInputComponent
                      {...field}
                      label="First Name"
                      fullWidth
                      required
                      error={updateErrors.firstName?.message}
                    />
                  )}
                />
                <Box sx={{ paddingTop: "4px" }}>
                  <Controller
                    name="lastName"
                    control={updateControl}
                    render={({ field }) => (
                      <TextInputComponent
                        {...field}
                        label="Last Name"
                        fullWidth
                      />
                    )}
                  />
                </Box>
              </div>
              <Controller
                name="fbUrl"
                control={updateControl}
                render={({ field }) => (
                  <TextInputComponent
                    {...field}
                    label="Facebook Profile URL"
                    fullWidth
                    required={isFbUrlRequired}
                    error={updateErrors.fbUrl?.message}
                  />
                )}
              />
              <Controller
                name="email"
                control={updateControl}
                render={({ field }) => (
                  <TextInputComponent
                    {...field}
                    label="Email"
                    type="email"
                    fullWidth
                    required
                    error={updateErrors.email?.message}
                  />
                )}
              />
              <Controller
                name="status"
                control={updateControl}
                render={({ field }) => (
                  <SelectInputComponent
                    {...field}
                    label="Status"
                    options={USER_STATUS_OPTIONS}
                    fullWidth
                    required
                    error={updateErrors.status?.message}
                  />
                )}
              />
            </Box>
            <Divider />
            <Box sx={{ padding: "0.8rem 1.2rem" }}>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                className="w-full py-3 mt-6"
              >
                Update
              </Button>
            </Box>
          </form>
        </Paper>
      </Box>

      <Box sx={{ margin: "auto", marginBottom: "1em" }}>
        <Paper variant="outlined" sx={{ borderRadius: "8px" }}>
          <Box sx={{ padding: "0.8rem 1.2rem" }}>
            <Typography variant="h2" component="h2" gutterBottom>
              Reset Password
            </Typography>
          </Box>
          <Divider />
          <form onSubmit={handleResetPasswordSubmit(onSubmitReset)}>
            <Box sx={{ padding: "0.8rem 1.2rem" }}>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-12">
                <Controller
                  name="password"
                  control={resetPasswordControl}
                  render={({ field }) => (
                    <TextInputComponent
                      {...field}
                      label="Password"
                      type="password"
                      fullWidth
                      required
                      error={resetPasswordErrors.password?.message}
                    />
                  )}
                />
                <Controller
                  name="confirmPassword"
                  control={resetPasswordControl}
                  render={({ field }) => (
                    <TextInputComponent
                      {...field}
                      label="Confirm Password"
                      type="password"
                      fullWidth
                      required
                      error={resetPasswordErrors.confirmPassword?.message}
                    />
                  )}
                />
              </div>
            </Box>
            <Divider />
            <Box sx={{ padding: "0.8rem 1.2rem" }}>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                className="w-full py-3 mt-6"
                disabled={!canResetPassword}
              >
                Reset Password
              </Button>
              {!canResetPassword && (
                <Typography color="error" sx={{ mt: 2 }}>
                  Cannot reset password: Facebook username is missing
                </Typography>
              )}
            </Box>
          </form>
        </Paper>
      </Box>

      <Box sx={{ margin: "auto", marginBottom: "1em" }}>
        <Paper variant="outlined" sx={{ borderRadius: "8px" }}>
          <Box sx={{ padding: "0.8rem 1.2rem" }}>
            <Typography variant="h2" component="h2" gutterBottom>
              Set Search Params
            </Typography>
          </Box>
          <Divider />
          <Box sx={{ padding: "0.8rem 1.2rem" }}>
            <Typography variant="h6" component="h3" gutterBottom className="!text-xs !font-semibold">
              Current Search Params
            </Typography>
            {paramsData.length > 0 ? (
              <TableContainer component={Paper} className="overflow-x-auto border border-top-2">
                <Table sx={{ minWidth: 650 }} aria-label="search params table">
                  <TableHead>
                    <TableRow>
                      <TableCell>Feature Name</TableCell>
                      <TableCell>Params</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {paramsData.map((row) => (
                      <TableRow key={row.paramId}>
                        <TableCell component="th" scope="row">
                          {row.featureName}
                        </TableCell>
                        <TableCell>
                          {Object.entries(row.params).map(([key, value]) => (
                            <div key={key}>
                              <strong>{key}:</strong>{' '}
                              {value !== undefined && value !== null
                                ? (typeof value === 'boolean'
                                    ? (value ? 'ON' : 'OFF')
                                    : value)
                                : '-'}
                            </div>
                          ))}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            ) : (
              <Typography variant="body1">No search params data available.</Typography>
            )}
          </Box>
          <Divider />
          <form
            onSubmit={handleSetSearchParamsSubmit(onSubmitSearchParams)}
            noValidate
          >
            <Box sx={{ padding: "0.8rem 1.2rem" }}>
              <Controller
                name="featuredName"
                control={searchParamsControl}
                render={({ field }) => (
                  <SelectInputComponent
                    {...field}
                    label="Select Feature Name"
                    options={Object.keys(searchParams).map((key) => ({
                      value: key,
                      label: key,
                    }))}
                    fullWidth
                    required
                    onChange={(e) => {
                      field.onChange(e);
                      handleFeatureChange(e);
                    }}
                    error={searchParamsErrors.featuredName?.message}
                  />
                )}
              />
              {selectedFeature &&
                searchParams[selectedFeature].map((param) => (
                  <Box key={param.name} sx={{ mt: 2 }}>
                    {param.type === "boolean" ? (
                      <Controller
                        name={param.name}
                        control={searchParamsControl}
                        render={({ field }) => (
                          <>
                            <Typography>
                              {param.name}
                              {param.description && (
                                <FormHelperText
                                  sx={{
                                    marginLeft: 1,
                                    fontStyle: "italic",
                                    fontWeight: 600,
                                    fontSize: "0.77rem",
                                    letterSpacing: 0,
                                  }}
                                  component="span"
                                >
                                  ({param.description})
                                </FormHelperText>
                              )}
                            </Typography>
                            <RadioGroup
                              {...field}
                              row
                              value={
                                field.value === null ||
                                field.value === undefined
                                  ? ""
                                  : String(field.value)
                              }
                            >
                              <FormControlLabel
                                value="true"
                                control={<Radio />}
                                label="ON"
                              />
                              <FormControlLabel
                                value="false"
                                control={<Radio />}
                                label="OFF"
                              />
                              <FormControlLabel
                                value=""
                                control={<Radio />}
                                label="NONE"
                              />
                            </RadioGroup>
                          </>
                        )}
                      />
                    ) : (
                      <Controller
                        name={param.name}
                        control={searchParamsControl}
                        render={({ field }) => (
                          <TextInputComponent2
                            {...field}
                            label={param.name}
                            type={param.type}
                            fullWidth
                            error={searchParamsErrors[param.name]?.message}
                            helperText={param.description}
                            inputProps={
                              param.type === "number"
                                ? { min: param.min, max: param.max }
                                : {}
                            }
                          />
                        )}
                      />
                    )}
                  </Box>
                ))}
            </Box>
            <Divider />
            <Box sx={{ padding: "0.8rem 1.2rem" }}>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                className="w-full py-3 mt-6"
              >
                Update Search Params
              </Button>
            </Box>
          </form>
        </Paper>
      </Box>

      <AlertSnackbar
        open={snackbar.open}
        handleClose={handleCloseSnackbar}
        message={snackbar.message}
        severity={snackbar.severity}
      />

      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isLoading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
}

export default UserDetailsPage;
